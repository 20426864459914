import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import notFound from '../svg/not_found.svg';
import productData from '../data/product-data';
import {Link} from 'gatsby';
import * as entities from 'entities';

const NotFoundPage = () => (
  <div className="application">
    <Helmet>
      <title>Seite nicht gefunden | Automatiktür24</title>
      <meta charSet="utf-8" />
      <html lang="de" />
    </Helmet>
    <Layout>

      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Fehler 404
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Seite nicht gefunden
            </p>
            <img src={notFound} alt="Seite nicht gefunden" width="300"/>
          </div>

          <div className="lg:w-1/2">
            Meinten Sie vielleicht...
            <ul className="mt-8">
              {productData.map(product => (
                <li><Link to={product.productPage}>{entities.decodeHTML(product.title)}</Link></li>
              ))}
            </ul>
          </div>
        </div>
      </section>

    </Layout>
  </div>
);

export default NotFoundPage
